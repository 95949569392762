<template>
  <div class="manage-options">
    <div class="title-bar">
      <h4 class="on">分类管理</h4>
      <h4 @click="openFilterOptions">筛选配置</h4>
    </div>

    <el-button
      icon="el-icon-plus"
      size="mini"
      class="add-button"
      @click="handleOptionsCommand('sibling', {}, {})"
    >
      添加分类
    </el-button>

    <el-tree
      :data="treeList"
      :show-checkbox="false"
      node-key="configurationId"
      default-expand-all
      :expand-on-click-node="false"
      @node-click="treeNodeClick"
      :props="treeProps"
    >
      <span
        class="custom-tree-node"
        slot-scope="{ node, data }"
        :style="{
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'space-between',
          'width': '100%',
          'color': node.isCurrent ? '#2861E2' : '',
          'padding': '0 10px 0 0',
          'user-select': 'none',
          'overflow': 'hidden'
        }"
      >
        <span
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 80%"
        >
          {{ data.configurationName }}
        </span>

        <el-dropdown
          trigger="click"
          v-if="node.isCurrent"
          @command="handleOptionsCommand($event, node, data)"
        >
          <i class="el-icon-setting"></i>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="sibling">新建同级</el-dropdown-item>
            <el-dropdown-item command="next" v-if="node.level < 3">新建下级</el-dropdown-item>
            <el-dropdown-item command="edit">编辑</el-dropdown-item>
            <el-dropdown-item command="del">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </el-tree>

    <el-dialog :title="formDialogTitle" :visible.sync="formDialogVisible" width="500px" top="15vh">
      <el-form
        :model="optionsForm"
        status-icon
        :rules="optionsRules"
        ref="OptionsForm"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="分类ID：">
          <el-input v-model="optionsForm.configurationId" autocomplete="none" disabled></el-input>
        </el-form-item>

        <el-form-item label="分类名称：" prop="configurationName">
          <el-input v-model="optionsForm.configurationName" autocomplete="none"></el-input>
        </el-form-item>

        <el-form-item label="分类排序：" prop="configurationSort">
          <el-input-number
            v-model.number="optionsForm.configurationSort"
            :min="1"
            :max="10"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleFormDialogCancel" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleFormDialogOk" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <FilterOptionsDialog ref="FilterOptionsDialog" @refresh="filtersRefresh" />
  </div>
</template>

<script>
import FilterOptionsDialog from './FilterOptionsDialog.vue';

import {
  getConfigurationList,
  saveConfiguration,
  delConfiguration
} from '@/api/miniprogramProducts';

export default {
  components: {
    FilterOptionsDialog
  },
  data() {
    return {
      treeList: [],
      formDialogVisible: false,
      formDialogTitle: '分类项操作',
      optionsForm: {
        configurationId: '',
        configurationName: '',
        configurationSort: 1,
        parentId: '',
        isPublish: 1
      },
      optionsRules: {
        configurationName: { required: true, message: '请输入分类名称', trigger: 'blur' },
        configurationSort: { required: true, message: '请输入分类排序标号', trigger: 'blur' }
      },
      treeProps: {
        label: 'configurationName',
        children: 'childList'
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    openFilterOptions() {
      this.$refs.FilterOptionsDialog.open();
    },
    treeNodeClick(itemData, node) {
      console.log(itemData, node);
      this.$emit('treeClick', node.level == 3, itemData);
    },
    handleFormDialogCancel() {
      this.formDialogVisible = false;
    },
    handleFormDialogOk() {
      this.$refs.OptionsForm.validate(async (valid) => {
        if (!valid) return;
        const res = await saveConfiguration({
          data: { ...this.optionsForm }
        });

        if (res.success) {
          this.$message.success(res.description || '保存成功');
          this.getList();
          this.handleFormDialogCancel();
        }
      });
    },
    handleOptionsCommand(command, node, data) {
      console.log(command, node, data);

      if (command == 'sibling' || command == 'next' || command == 'edit') {
        this.formDialogVisible = true;

        this.$nextTick(() => {
          this.$refs.OptionsForm.clearValidate();

          if (command == 'sibling' || command == 'next') {
            Object.keys(this.optionsForm).forEach((key) => {
              this.optionsForm[key] = '';
            });

            this.optionsForm.isPublish = 1;
            if (command == 'next') this.optionsForm.parentId = data.configurationId;
            if (command == 'sibling') this.optionsForm.parentId = data.parentId || '';
          }

          if (command == 'edit') this.optionsForm = Object.assign(this.optionsForm, { ...data });
        });
      }

      if (command == 'del') {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            const res = await delConfiguration({ data: { configurationId: data.configurationId } });

            if (res.success) {
              this.$message({
                type: 'success',
                message: res.description || '删除成功!'
              });

              this.getList();
            }
          })
          .catch((err) => {
            console.log(err || '');
          });
      }
    },
    async getList() {
      try {
        const res = await getConfigurationList({ data: {} });

        this.treeList = res.data || [];
      } catch (err) {
        console.log(err);
      }
    },
    filtersRefresh() {
      this.$emit('filtersRefresh');
    }
  }
};
</script>

<style lang="scss" scoped>
.manage-options {
  width: 220px;
  // height: 100%;
  // overflow-y: auto;
  color: #000000;
  padding: 20px;

  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 15px;
    position: sticky;
    top: 0;
    user-select: none;

    > h4 {
      cursor: pointer;
      font-weight: 500;
      margin: 0;
    }

    .on {
      color: #2861e2;
    }
  }

  .add-button {
    margin: 10px 0;
  }
}
</style>
