import { render, staticRenderFns } from "./SampleList.vue?vue&type=template&id=36c757d8&scoped=true&"
import script from "./SampleList.vue?vue&type=script&lang=js&"
export * from "./SampleList.vue?vue&type=script&lang=js&"
import style0 from "./SampleList.vue?vue&type=style&index=0&id=36c757d8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c757d8",
  null
  
)

export default component.exports