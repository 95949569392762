<template>
  <div class="filter-options-dialog" v-if="visible">
    <el-dialog :title="title" :visible.sync="visible" width="600px" top="10vh">
      <el-button size="mini" class="add-button" @click="addFilter" type="primary">添加</el-button>

      <div class="filter-options-dialog-content">
        <div v-for="item in list" :key="item.configurationFilterId">
          <el-dropdown @command="handleFilter1Command($event, item)">
            <span style="cursor: pointer; font-size: 16px">
              {{ item.configurationFilterName }}
              <i class="el-icon-arrow-down el-icon--right" style="font-size: 12px"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="next">添加下级</el-dropdown-item>
              <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-dropdown-item command="del">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div class="second-filter-list">
            <el-dropdown
              @command="handleFilter1Command($event, item2)"
              v-for="item2 in item.childList"
              :key="item2.configurationFilterId"
              class="filter-secod-item"
            >
              <span style="cursor: pointer; font-size: 14px">
                {{ item2.configurationFilterName }}
                <i class="el-icon-arrow-down el-icon--right" style="font-size: 12px"></i>
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit">编辑</el-dropdown-item>
                <el-dropdown-item command="del">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="formDialogTitle"
      :visible.sync="formDialogVisible"
      width="500px"
      top="13vh"
      append-to-body
    >
      <el-form
        :model="filterForm"
        status-icon
        :rules="filterRules"
        ref="FilterForm"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="筛选项ID：">
          <el-input
            v-model="filterForm.configurationFilterId"
            autocomplete="none"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="名称：" prop="configurationFilterName">
          <el-input v-model="filterForm.configurationFilterName" autocomplete="none"></el-input>
        </el-form-item>

        <el-form-item label="排序：" prop="sortNum">
          <el-input-number
            v-model.number="filterForm.sortNum"
            :min="1"
            :max="10"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleFormDialogCancel" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleFormDialogOk" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getConfigurationFilters,
  saveConfigurationFilters,
  delConfigurationFilters
} from '@/api/miniprogramProducts.js';

export default {
  data() {
    return {
      title: '筛选配置',
      visible: false,
      formDialogVisible: false,
      formDialogTitle: '筛选项操作',
      filterForm: {
        configurationFilterId: '',
        configurationFilterName: '',
        sortNum: 1,
        parentId: ''
      },
      filterRules: {
        configurationFilterName: { required: true, message: '请输入筛选项名称', trigger: 'blur' },
        sortNum: { required: true, message: '请输入排序标号', trigger: 'blur' }
      },
      list: []
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getList();
      }

      if (typeof newVal == 'boolean' && !newVal) {
        this.$emit('refresh');
      }
    }
  },
  methods: {
    handleCancel() {
      this.visible = false;
    },
    async getList() {
      try {
        const res = await getConfigurationFilters({ data: {} });

        this.list = res.data || [];
      } catch (err) {
        console.log(err);
      }
    },
    open() {
      this.visible = true;
    },
    close() {
      this.handleCancel();
    },
    handleFilter1Command(command, item) {
      if (command == 'next' || command == 'edit') {
        this.formDialogVisible = true;

        this.$nextTick(() => {
          this.$refs.FilterForm.clearValidate();

          if (command == 'next') {
            this.resetForm();

            this.filterForm = Object.assign(this.filterForm, {
              parentId: item.configurationFilterId
            });
          }

          if (command == 'edit') this.filterForm = { ...item };
        });
      }

      if (command == 'del') {
        this.$confirm('是否继续删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            const res = await delConfigurationFilters({
              data: { configurationFilterId: item.configurationFilterId }
            });

            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });

              this.getList();
            }
          })
          .catch((err) => console.log(err || ''));
      }
    },
    handleFormDialogCancel() {
      this.formDialogVisible = false;
    },
    handleFormDialogOk() {
      this.$refs.FilterForm.validate(async (validate) => {
        if (!validate) return;
        const res = await saveConfigurationFilters({
          data: { ...this.filterForm }
        });

        if (res.success) {
          this.$message.success(res.description || '操作成功');
          this.handleFormDialogCancel();
          this.getList();
        }
      });
    },
    addFilter() {
      this.formDialogVisible = true;

      this.$nextTick(() => {
        this.$refs.FilterForm.clearValidate();
        this.resetForm();
      });
    },
    resetForm() {
      Object.keys(this.filterForm).forEach((el) => {
        this.filterForm[el] = '';
      });
    }
  }
};
</script>

<style lang="less" scoped>
.filter-options-dialog {
  box-sizing: border-box;
  color: #333;
  position: relative;

  .add-button {
    position: absolute;
    right: 30px;
    top: 60px;
  }

  .filter-options-dialog-content {
    box-sizing: border-box;

    .second-filter-list {
      margin: 15px;
      box-sizing: border-box;

      .filter-secod-item {
        margin: 0 10px 10px 0;
      }
    }
  }
}
</style>
