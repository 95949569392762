import request from '@/utils/request';

/**
 * 获取样品列表
 * @param {*} data
 * @returns
 */
export function getSampleList(data) {
  return request({
    url: 'product-web-api/admin/sample/list',
    method: 'POST',
    data
  });
}

/**
 *  获取样品详情
 * @param {*} data
 * @returns
 */
export function getSampleDetai(data) {
  return request({
    url: 'product-web-api/admin/sample/queryById',
    method: 'POST',
    data
  });
}

/**
 * 获取样品列表
 * @param {*} data
 * @returns
 */
export function getSampleTypeTree(data) {
  return request({
    url: 'product-web-api/admin/sampleCategory/list',
    method: 'POST',
    data
  });
}

export default {};
