<template>
  <div class="content-item">
    <el-button icon="el-icon-delete" type="text" class="del-button" @click="delItem"></el-button>

    <el-form
      :model="formData"
      :rules="formRules"
      ref="FormData"
      label-width="100px"
      size="mini"
      label-position="right"
    >
      <div class="form-wrap">
        <el-form-item prop="productUrl" label-width="0px">
          <div class="sample-choose" @click="openChooseSampleDialog">
            <template v-if="!formData.productUrl">
              <i class="el-icon-plus plus-icon"></i>
              <span class="tips">从样品中选择 产品封面图</span>
            </template>

            <img :src="formData.productUrl" v-if="formData.productUrl" />
          </div>
        </el-form-item>

        <div class="space1">
          <el-form-item label="定位产品：" prop="productSetId">
            <el-input
              v-model="formData.productSetId"
              autocomplete="none"
              type="number"
              @blur="getProductDetails"
              @input="productSetIdInput"
              placeholder="请输入产品ID"
            ></el-input>
          </el-form-item>

          <el-form-item label="跳转详情页：" prop="productDetailId">
            <el-select v-model="formData.productDetailId" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in productDetailOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="排序：" prop="configurationSubSort">
            <el-input-number
              v-model.number="formData.configurationSubSort"
              :min="1"
              :max="10"
              style="width: 100%"
            ></el-input-number>
          </el-form-item>
        </div>

        <div class="space2">
          <el-form-item
            :label="`${item.configurationFilterName}：`"
            v-for="(item, index) in filterList"
            :key="index"
          >
            <el-select
              v-model="filterCheckedObj[item.configurationFilterId]"
              placeholder="请选择"
              style="width: 100%"
              multiple
            >
              <el-option
                v-for="item2 in item.childList"
                :key="item2.configurationFilterId"
                :label="item2.configurationFilterName"
                :value="item2.configurationFilterId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <AddSampleModal ref="addSampleModal" @chooseSampleImage="chooseSampleImage" />
  </div>
</template>

<script>
import AddSampleModal from './AddSampleModal/index.vue';
import { getProductDetailList } from '@/api/miniprogramProducts';

export default {
  components: {
    AddSampleModal
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    filterList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          Object.keys(this.formData).forEach((key) => {
            this.formData[key] = newVal[key] || '';
          });

          this.filterListFromItem = newVal.filterList || [];
          this.showFiltersSelectList();
        }
      }
    },
    filterList: {
      deep: true,
      immediate: true,
      handler(newVal) {
        const obj = {};

        newVal &&
          newVal.forEach((el) => {
            obj[el.configurationFilterId] = [];
          });

        this.filterCheckedObj = obj;
        this.showFiltersSelectList();
      }
    }
  },
  data() {
    var validateProductUrl = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择产品封面图'));
      } else callback();
    };

    return {
      formData: {
        configurationSubId: '',
        productUrl: '',
        productSetId: '',
        productDetailId: '',
        configurationSubSort: 1,
        filterSaveList: [],
        localPush: true
      },
      formRules: {
        productSetId: { required: true, message: '请输入产品ID', trigger: 'blur' },
        productDetailId: { required: true, message: '请选择产品详情页', trigger: 'change' },
        configurationSubSort: { required: true, message: '请输入排序标号', trigger: 'blur' },
        productUrl: { validator: validateProductUrl, required: true }
      },
      productDetailOptions: [],
      filterCheckedObj: [],
      filterListFromItem: []
    };
  },
  created() {
    this.getProductDetails();
  },
  methods: {
    chooseSampleImage(e) {
      this.formData.productUrl = e || '';
    },
    openChooseSampleDialog() {
      this.$refs['addSampleModal'].open();
    },

    //外部调用方法
    async validAndGetItem() {
      const flag = await new Promise((resolve) => {
        this.$refs.FormData.validate((valid) => resolve(valid));
      });

      const arr = [];
      Object.values(this.filterCheckedObj).forEach((el) => {
        arr.push(...(el || []));
      });

      this.formData.filterSaveList = arr;
      if (this.formData.localPush) this.formData.configurationSubId = '';
      return [flag, this.formData];
    },
    delItem() {
      this.$emit('delItemClick');
    },
    getProductDetails() {
      if (!this.formData.productSetId) return;

      getProductDetailList({
        data: {
          selectRelationProductId: this.formData.productSetId,
          selectRelationProductType: '9'
        }
      })
        .then((res) => {
          this.productDetailOptions = ((res.data || {}).content || []).map((el) => ({
            label: el.productName,
            value: el.productId
          }));
        })
        .catch((err) => console.log(err));
    },
    productSetIdInput() {
      this.formData.productDetailId = '';
      this.productDetailOptions = [];
    },
    showFiltersSelectList() {
      this.filterListFromItem.forEach((el) => {
        this.filterCheckedObj[el.configurationFilterId] = el.childIdList || [];
      });
    }
  }
};
</script>

<style lang="scss" scped>
.content-item {
  box-sizing: border-box;
  background-color: #f8f8f8;
  padding: 16px;
  position: relative;
  margin: 0 20px 20px 20px;

  .del-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .form-wrap {
    display: flex;

    .sample-choose {
      width: 144px;
      height: 144px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px dashed #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;

      .plus-icon {
        font-size: 32px;
        color: #9a9d9e;
      }

      .tips {
        font-size: 12px;
        color: #363f47;
        display: block;
        margin: 5px 28px;
        text-align: center;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .space1 {
      flex: 1;
      margin: 0 30px;
    }

    .space2 {
      flex: 1;
      margin-right: 30px;
    }
  }
}
</style>
