<template>
  <div class="sample-list" v-if="visible">
    <el-dialog
      :title="title"
      :visible="visible"
      width="1140px"
      :before-close="handleCancel"
      top="8vh"
    >
      <section class="sample-list-content">
        <SampleList ref="sampleList" @change="tableChange" />

        <div class="images-choose">
          <div class="img-item-wrap" v-for="(item, index) in currentAlbums" :key="item">
            <img :src="handleImageUrl(item)" @click="currentIndex = index" />

            <i class="el-icon-success" v-if="index === currentIndex"></i>
          </div>
        </div>
      </section>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleOk" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SampleList from './SampleList.vue';
import { getSampleDetai } from '@/api/sample';

export default {
  components: {
    SampleList
  },
  data() {
    return {
      title: '选择样片',
      visible: false,
      sampleId: '',
      currentAlbums: [],
      currentIndex: 0
    };
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.$refs.sampleList.handleReset();
        this.currentAlbums = [];
        this.currentIndex = 0;
      }
    }
  },
  mounted() {},
  methods: {
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      if (this.currentAlbums.length > 0) {
        this.$emit('chooseSampleImage', this.currentAlbums[this.currentIndex]);
      }

      this.visible = false;
    },
    async getDetail() {
      try {
        const params = { sampleId: this.sampleId };
        const response = await getSampleDetai({ data: params });
        this.currentAlbums = response ? response.albums : [];
      } catch (err) {
        console.log(err);
      }
    },
    tableChange(val) {
      if (!val || val.length === 0) return;
      this.sampleId = val[0].sampleId;
      this.getDetail();
      this.currentIndex = 0;
    },
    handleImageUrl(url) {
      if (!url) return url;
      if (url.includes('?')) return url + '&x-oss-process=image/quality,q_40';
      return url + '?x-oss-process=image/quality,q_40';
    },
    open() {
      this.visible = true;
    },
    close() {
      this.handleCancel();
    }
  }
};
</script>

<style lang="less" scoped>
.sample-list {
  .sample-list-content {
    display: flex;
    overflow: hidden;

    .images-choose {
      display: inline-flex;
      width: 400px;
      flex-wrap: wrap;
      overflow-y: auto;
      overflow-x: hidden;
      align-content: flex-start;
      height: 500px;

      > :nth-child(n) {
        flex-shrink: 0;
      }

      .img-item-wrap {
        position: relative;
        width: 80px;
        height: 80px;
        margin: 0 8px 8px 0;
        border-radius: 8px;
        overflow: hidden;

        img {
          cursor: pointer;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          top: 4px;
          right: 3px;
          background-color: #ffffff;
          color: #17d5cc;
          border-radius: 50%;
          font-size: 20px;
        }
      }
    }
  }

  /deep/ .el-dialog__wrapper {
    .el-dialog {
      margin: 0 auto 0 284px;
    }
  }
}
</style>
